import React, { Component } from 'react';
import { ListGroupItem } from 'reactstrap';

const flushToGrade = require('../images/flushToGrade.png');
const flushToLanding = require('../images/flushToLanding.png');
const dropToGrade = require('../images/dropToGrade.png');
const dropToLanding = require('../images/dropToLanding.png');

export class StairConfigurationItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.isActive,
        };

        switch (this.props.value) {
            case '0':
                this.state.configImage = flushToGrade;
                break;
            case '1':
                this.state.configImage = flushToLanding;
                break;
            case '2':
                this.state.configImage = dropToGrade;
                break;
            case '3':
                this.state.configImage = dropToLanding;
                break;
            default:
                break;
        }
    };

    handleClick(isActive) {
        this.setState((state, props) => ({
            active: isActive
        }));

        this.props.onClick();
    };

    render() {
        return (
            <ListGroupItem data-toggle="list" className={this.props.className} onClick={() => this.handleClick(!this.state.active)}>
                <img alt="configImage" src={String(this.state.configImage)} /> <br />
                {this.props.text}
            </ListGroupItem>
        );
    };
}