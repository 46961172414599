import React, { Component } from 'react';

export class StepSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
        };
    };

    handleStepOptionSelect(e) {
        //const target = e.target;
        //const value = target.value;
        //const name = target.name;

        this.props.handleStepOptionSelect(e);
    }

    render() {
        return (
            <div className={`${(this.props.hasErrors > 0) ? 'hide' : ''}`}>
                <h5><span className="step">4</span> Select a Step Configuration</h5>
                <p className={this.props.stepCount <= 0 ? 'hide' : ''}>{this.props.stepCount} step options are available for the values provided. Select an option below to view the results.</p>
                <p className={this.props.stepCount > 0 ? 'hide' : ''}>Sorry, no step options are available for the values provided.</p>

                <select name="stepSelect" className={`form-select col-6 ${(this.props.stepCount <= 0) ? 'hide' : ''}`} onChange={(e) => this.handleStepOptionSelect(e)}>
                    <option value="0">Please Select...</option>
                    {this.props.stepOptions.map((opt) => <option key={JSON.stringify(opt)} value={JSON.stringify(opt)} data-step={JSON.stringify(opt)}>{opt.steps} Steps - {opt.totalRun}" Total Run</option>)}
                </select>
            </div>
        );
    };
}