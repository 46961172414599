import React, { Component } from 'react';
import TotalRiseModal from './modals/TotalRiseModal';
import TreadDepthModal from './modals/TreadDepthModal';

export class RiseAndTread extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
        };
    };

    handleInputChange(e) {
        //const target = e.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;

        this.props.handleInputChange(e);
    }

    focus(e) {
        e.target.select();
    };

    render() {
        return (
            <div>
                <h5><span className="step">2</span> Rise and Tread</h5>
                <p>Total Rise: <TotalRiseModal title="Total Rise" className="modal-dialog-scrollable" /></p>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="sr-only" htmlFor="totalRiseFeet">Total Rise (ft)</label>
                        <div className="input-group input-group-sm">
                            <input type="number" className="form-control" id="totalRiseFt" name="totalRiseFt" min="0" maxLength="5" step=".01" lang="en" onFocus={(e) => this.focus(e)} defaultValue={this.props.totalRiseFt} onChange={(e) => this.handleInputChange(e)} />
                            <div className="input-group-text">ft</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="sr-only" htmlFor="totalRiseInches">Total Rise (in)</label>
                        <div className="input-group input-group-sm">
                            <input type="number" className="form-control" id="totalRiseIn" name="totalRiseIn" min="0" maxLength="5" step=".01" onFocus={(e) => this.focus(e)} defaultValue={this.props.totalRiseIn} onChange={(e) => this.handleInputChange(e)} />
                            <div className="input-group-text">in</div>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="treadDepth">Tread Depth:</label> <TreadDepthModal title="Tread Depth" className="modal-dialog-scrollable" />
                        <div className="input-group input-group-sm">
                            <input type="number" className="form-control" id="treadDepthIn" name="treadDepthIn" min="11" max="12.25" maxLength="5" step=".01" onFocus={(e) => this.focus(e)} defaultValue={this.props.treadDepthIn} onChange={(e) => this.handleInputChange(e)} />
                            <div className="input-group-text">in</div>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className={this.props.hideThickness ? 'col-md-6 invisible' : 'col-md-6'}>
                        <label htmlFor="treadThickness">Tread Thickness:</label>
                        <div className="input-group input-group-sm">
                            <input type="number" className="form-control" id="treadThicknessIn" name="treadThicknessIn" min="0" max="1.5" maxLength="3" step=".01" onFocus={(e) => this.focus(e)} defaultValue={this.props.treadThicknessIn} onChange={(e) => this.handleInputChange(e)} />
                            <div className="input-group-text">in</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

// className={this.props.selectVisible ? 'show' : 'hide'}
//</div>

//    <div className={this.props.selectVisible ? 'hide' : 'show'}>