import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const mainDiagram = require('../../images/master-diagram.png');

const HelpModal = (props) => {
    const {
        className,
        title
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <a href="#" data-toggle="modal" className="text-secondary link-underline-light float-end" onClick={toggle}><span className="badge rounded-pill bg-secondary">?</span> Help</a>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <img src={mainDiagram} className="img-fluid" />
                    <p>&nbsp;</p>

                    <h5>Definitions</h5>

                    <h6>Total Rise</h6>
                    <p>Distance from where the bottom step sits to the top of the deck frame.  The thickness of the deck board IS NOT included in this measurement.</p> 

                    <h6>Tread Depth</h6>
                    <p>The width of the stair tread.  This dimension includes any gap between the boards that exists.</p>

                    <h6>Tread Thickness</h6>
                    <p>The thickness of the tread surface.</p>

                    <h6>Step Rise</h6>
                    <p>The distance from the top of one stair tread to the top of the next stair tread.</p>

                    <h6>Step Run</h6>
                    <p>The distance from the back of the step to the front edge of the stair tread.</p>

                    <h6>Stair Tread</h6>
                    <p>The material used to go over the Evolution Stair Tray that becomes the surface of the step.This is the surface that is walked on.</p>  

                    <h6>Bracket Spacing</h6>
                    <p>The distance between two Evolution Adjustable Stair Brackets.Reference the installation guide for further information on this measurement.</p>

                    <h6>Angle</h6>
                    <p>The angle of the Evolution Stringer relative to the mounting surface.Reference the installation guide for further information on this measurement.</p>

                    <h6>Stringer Length</h6>
                    <p>To total length the Evolution Stringer is cut to.Reference the installation guide for further information on this measurement.</p>

                    <h6>Pin Hole Locations</h6>
                    <p>The hole locations on the Evolution Adjustable Stair Bracket.  Nylon pins mark the locations of the holes and corresponds to a different rise / run setting on the bracket.  Reference the installation guide for further information on the hole locations.</p> 

                    <h6>Evolution 7”/11” Stair Bracket:  </h6>
                    <p>The Evolution Preset 7"/11" Stair Bracket is engineered to slide over the top of an Evolution Joist or Evolution Beam and provide a perfect 7" rise and 11" run for the stringer. These brackets align directly with one another resulting in a rapid, worry-free stringer installation.</p> 

                    <h6>Evolution 7.75”/11” Stair Bracket</h6>
                    <p>The Evolution Preset 7.75"/11" Stair Bracket is engineered to slide over the top of an Evolution Joist or Evolution Beam and provide a perfect 7.75" rise and 11" run for the stringer. These brackets align directly with one another resulting in a rapid, worry-free stringer installation.</p> 

                    <h6>Evolution Adjustable Stair Bracket</h6>
                    <p>The Evolution Adjustable Stair Bracket allows you to quickly and easily customize the stair’s rise and run using the two provided set pins. Place the set pins in the desired holes and slide the stair brackets over an Evolution Joist or Beam to complete the stinger installation.  The Fortress Stair Calculator is required to complete installation using this bracket.</p> 

                    <h6>Evolution Stringer</h6>
                    <p>The Evolution Stringer is assembled using an Evolution Joist or Evolution Beam and the Evolution Stair Brackets.  There are 3 separate brackets options and the brackets set on top of the Beam or Joist to create a stringer.  The Evolution Stringer is the main structural member of the stairs that the Evolution Stair Tray and Stair Tread material mount to.</p>    

                    <h6>Evolution 48” Stair Tray</h6>
                    <p>The Evolution Stair tray sits on top of the Stringer and is the surface that the stair tread will attach to. The lateral supports are spaced to work with any kind of deck board and provides blocking for surface mounted posts.</p>   

                    <h6>Evolution Stair Anchor Bracket</h6>
                    <p>The Evolution Stair Stringer Anchor Bracket is reversible, allowing a secure, connection from the stringer to the footing, slab or pier under the last tread.</p>  

                    <h6>Evolution Stair Strap</h6>
                    <p>The Evolution Stair Strap allows flexibility when connecting the top of the stair stringer to the deck, landing or structure, while securely holding the stringers in place.</p>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Got it!</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default HelpModal;