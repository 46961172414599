import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'; //faShareAlt
import jsPDF from 'jspdf';

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('');
};

//const evolutionLogo = require('../images/evolution-logo.png');
//const framingLogo = require('../images/framing-logo.png');
//const landingDiagrams = require('../images/landing-diagrams.png');
//const gradeDiagrams = require('../images/grade-diagrams.png');
let pdfImages = [
    //p11
    { name: 'p11diagram1', src: require('../images/p11diagram1.png') }, //541x1269
    //p12
    { name: 'p12diagram', src: require('../images/p12diagram.png') }, //680x1405
    //p13
    { name: 'p13diagram1', src: require('../images/p13diagram1.png') }, //745x411
    { name: 'p13diagram2', src: require('../images/p13diagram2.png') }, //1032x364
    { name: 'p13diagram3', src: require('../images/p13diagram3.png') }, //690x491
    //p14
    { name: 'p14diagram', src: require('../images/p14diagram.png') }, //687x1338
];

let pageNum = 1;

export class FixedRiseRunPDF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfButtonText: 'Download PDF'
        };

        this.init();
    };

    init() {
        let that = this;
        pdfImages.forEach(function (o, i) {
            that.toDataURL(o.src, function (dataUrl) {
                o.data = dataUrl;
            });
        });
    }

    getImageData(name) {
        let o = pdfImages.find(function (obj) { return obj.name === name; });
        if (o) return o.data;
        else return;
    }

    handleFixedRiseRunPdfClick(e) {
        let that = this;
        setTimeout(function () {
            that.generateFixedRiseRunPDF();
        }, 500);

        e.preventDefault();
    }

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    centerPos(pageWidth, objectWidth) {
        return ((pageWidth - objectWidth) / 2);
    };


    generateFixedRiseRunPDF = () => {
        let data = this.props.pdfData;
        let date = new Date();
        let doc = new jsPDF('p', 'pt');
        let settings = {
            width: doc.internal.pageSize.getWidth(),
            height: doc.internal.pageSize.getHeight()
        };

        doc.setFontSize(18);

        this.page11(doc, settings); // fixed rise/run (not needed in calc pdf)

        //Removed per Rob's feedback, May 2024
        // this.page12(doc, settings);
        // this.page13(doc, settings);
        // this.page14(doc, settings);


        doc.setFont('helvetica');
        doc.save('fortress-fixed-riserun-brackets_' + date.yyyymmdd() + '.pdf');

        let that = this; // and a wiffle ball bat!
        this.setState({
            pdfButtonText: 'Complete!'
        }, () => {
            setTimeout(function () {
                that.setState({
                    pdfButtonText: 'Download PDF'
                });
            }, 2000);
        });

        pageNum = 1;
    };


    //****************************************
    // Page 11
    //****************************************
    page11 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        //doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Fixed Rise Run Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        let p12introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The fixed Rise Run brackets are non - adjustable, and are to be used for stair rise / run of either 7”/11” or 7.75”/11”.', 560)
        doc.text((width / 2), 80, p12introCopy, { align: 'center', lineHeightFactor: 2 })

        doc.addImage(this.getImageData('p11diagram1'), 'PNG', 50, 120, 243.45, 571)

        // diagram 2 copy
        let p11diagramCopy1 = doc.splitTextToSize('Install the brackets on either an Evolution 2”x6” Joist or 2” x11” Beam.', 200);
        doc.text(width - 240, 120, p11diagramCopy1, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy2 = doc.splitTextToSize('Butt the brackets together; they should be touching when they’re fastened.', 200);
        doc.text(width - 240, 180, p11diagramCopy2, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy3 = doc.splitTextToSize('Fasten the brackets to the joists using the Evolution 3/4” Self-Drilling Screw.', 200);
        doc.text(width - 240, 240, p11diagramCopy3, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy4 = doc.splitTextToSize('Once all the brackets are installed use a silver permanent marker to mark the angles.', 200);
        doc.text(width - 240, 350, p11diagramCopy4, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy5 = doc.splitTextToSize('The angle does not have to be measured. Use a straight edge to continue angles on either side of the bracket.', 200);
        doc.text(width - 240, 410, p11diagramCopy5, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy6 = doc.splitTextToSize('Use a metal cutting saw and blade to make the marked cuts.', 200);
        doc.text(width - 240, 560, p11diagramCopy6, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy7 = doc.splitTextToSize('Install the assembled stringers using the Evolution Stair Strap and Evolution Anchor Bracket.', 200);
        doc.text(width - 240, 620, p11diagramCopy7, { align: 'left', lineHeightFactor: 2 });

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 12
    //****************************************
    page12 = (doc, settings) => {
        let width = settings.width;
        //let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Stair Strap', { align: 'center', lineHeightFactor: 2 });

        doc.addImage(this.getImageData('p12diagram'), 'PNG', 40, 90, 272, 562)

        let p12diagramCopy1 = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('Position the stair strap and fasten to the deck using the Evolution 3/4” Self-Drilling Screws', 170)
        doc.text(width - 250, 130, p12diagramCopy1, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy2 = doc.splitTextToSize('Install the Stringer by positioning it on the stair strap and fastening it using the holes on the side of the bracket and Evolution 3/4” Self-Drilling Screw (Not Pictured).', 170)
        doc.text(width - 250, 190, p12diagramCopy2, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy3 = doc.splitTextToSize('Once the Stringer is installed, use a rubber mallet to bend the bottom portion of the stair strap along the bottom side of the stringer.', 170)
        doc.text(width - 250, 310, p12diagramCopy3, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy4 = doc.splitTextToSize('Fill in all the holes on the bracket with the Evolution 3/4” Self-Drilling Screw.', 170)
        doc.text(width - 250, 390, p12diagramCopy4, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy5 = doc.splitTextToSize('Stair Strap fully installed.', 170)
        doc.text(width - 250, 560, p12diagramCopy5, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 13
    //****************************************
    page13 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Anchor Bracket', { align: 'center', lineHeightFactor: 2 });

        let p14introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The Evolution Anchor Bracket attaches the stringer to the concrete footing pad or the deck landing (typical installation).', 560)
        doc.text(width / 2, 80, p14introCopy, { align: 'center', lineHeightFactor: 2 })

        // diagram 1
        doc.addImage(this.getImageData('p13diagram1'), 'PNG', 40, 120, 372.5, 205.5)

        let p13diagram1Copy = doc.splitTextToSize('Attached the anchor bracket to the bottom of the stringer using the Evolution 3/4” Self-Drilling Screws.', 150)
        doc.text(width - 175, 190, p13diagram1Copy, { align: 'left', lineHeightFactor: 1.5 })
        
        // diagram 2
        doc.addImage(this.getImageData('p13diagram2'), 'PNG', this.centerPos(width, 516), 325, 516, 182)

        let p13diagram2Copy = doc.splitTextToSize('Use a 3/8” concrete wedge anchor to securely fasten the anchor bracket to the concrete.', 150)
        doc.text((width/2)-140, 390, p13diagram2Copy, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 3
        doc.addImage(this.getImageData('p13diagram3'), 'PNG', 40, 500, 345, 245.5)

        let p13diagram3Copy = doc.splitTextToSize('If anchoring to a landing, install blocking and attach the anchor bracket thru the blocking using 3/8” bolts, nuts, and washers.', 150)
        doc.text(width - 195, 600, p13diagram3Copy, { align: 'left', lineHeightFactor: 1.5 })


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 14
    //****************************************
    page14 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Stair Tray', { align: 'center', lineHeightFactor: 2 });

        let p15introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The stair trays are 48” wide with blocking spaced every 10” On-Center. The tray can span 48” between stringers in residential applications.', 500)
        doc.text((width/2) - 250, 80, p15introCopy, { align: 'left', lineHeightFactor: 2 })

        doc.addImage(this.getImageData('p14diagram'), 'PNG', 50, 115, 274.8, 535.2)

        let p14diagramCopy1 = doc.splitTextToSize('Stringers full assembled and anchored to the deck with the Evolution Stair Strap and anchored to the concrete with the Evolution Anchor Bracket.', 150)
        doc.text(width - 250, 175, p14diagramCopy1, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy2 = doc.splitTextToSize('Once the stringers are attached lay the Stair Tray on top of the stair brackets and center it.', 150)
        doc.text(width - 250, 340, p14diagramCopy2, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy3 = doc.splitTextToSize('Fasten the tray using (2) Evolution 3/4” Self Drilling Screws at each stringer.', 150)
        doc.text(width - 250, 420, p14diagramCopy3, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy4 = doc.splitTextToSize('The blocking every 10” On-Center can be used for railing post attach-ment and stair tread attachment.', 150)
        doc.text(width - 250, 540, p14diagramCopy4, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    // Page Outline
    addOutline = (doc) => {
        doc.setDrawColor(0, 102, 153) //dark blue
            .setLineWidth(2)
            .rect(10, 11, 583, 750);

        doc.setDrawColor(153, 194, 214) //light blue
            .setLineWidth(1)
            .rect(20, 20, 564, 730)
    };

    // Page Footer
    addFooter = (doc) => {
        doc.setFont('helvetica')

        let date = new Date();
        let copyright = '© 2019 Fortress Framing, LLC';
        let revision = 'Rev-' + date.yyyymmdd();

        // footer
        doc.setFontSize(10);
        doc.setFont('normal')
        doc.text(20, 775, pageNum.toString());

        doc.text(220, 775, copyright);
        doc.text(510, 775, revision);

        //console.log('Page ' + pageNum.toString() + ' built!');
    };

    render() {
        return (
            <span>
                <a href="#" className="" onClick={(e) => this.handleFixedRiseRunPdfClick(e)}>download and view instructions</a>            
             </span>
        );
    };
}