import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const mainDiagram = require('../../images/master-diagram.png');
const flushToGrade = require('../../images/flushToGrade.png');
const flushToLanding = require('../../images/flushToLanding.png');
const dropToGrade = require('../../images/dropToGrade.png');
const dropToLanding = require('../../images/dropToLanding.png');

const ConfigurationModal = (props) => {
    const {
        className,
        title
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <button type="button" className="btn btn-link text-secondary link-underline-light p-0 pb-1" onClick={toggle}><span className="badge rounded-pill bg-secondary">i</span>  View configuration definitions</button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <img src={mainDiagram} className="img-fluid" />
                    <p>&nbsp;</p>

                    <h5>Configurations</h5>
                    <h6>Flush To Grade</h6>
                    <p>The top step is even with the surface of the deck.  The bottom step sits on TOP of a surface; typically a concrete slab or the top of a landing. </p>
                    <p class="text-center">
                        <img src={flushToGrade} className="img-fluid config" />
                    </p>

                    <h6>Flush to Landing</h6>
                    <p>The top step is even with the surface of a deck.  The bottom step attaches to the side of another structure; typically a landing.</p>
                    <p class="text-center">
                        <img src={flushToLanding} className="img-fluid config" />
                    </p>

                    <h6>Drop to Grade</h6>
                    <p>The top step drops down from the surface of the deck.  The bottom step sits on TOP of a surface; typically a concrete slab or the top of a landing.</p>
                    <p class="text-center">
                        <img src={dropToGrade} className="img-fluid config" />
                    </p>

                    <h6>Drop to Landing</h6>
                    <p>The top step drops down from the surface of the deck.  The bottom step attaches to the side of another structure; typically a landing.</p>
                    <p class="text-center">
                        <img src={dropToLanding} className="img-fluid config" />
                    </p>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Got it!</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ConfigurationModal;