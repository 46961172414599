import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'; //faShareAlt
import jsPDF from 'jspdf';

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('');
};

//const evolutionLogo = require('../images/evolution-logo.png');
//const framingLogo = require('../images/framing-logo.png');
//const landingDiagrams = require('../images/landing-diagrams.png');
//const gradeDiagrams = require('../images/grade-diagrams.png');
let pdfImages = [
    { name: 'evolutionLogo', src: require('../images/evolution-logo.png') },
    { name: 'framingLogo', src: require('../images/framing-logo.png') },
    { name: 'landingDiagrams', src: require('../images/landing-diagrams.png') }, //811x226
    { name: 'p2diagram2', src: require('../images/p2diagram2.png') }, //988x202   //
    //p3
    { name: 'bracketHoleLocations', src: require('../images/bracket-hole-locations.png') }, //919x582
    //p4
    { name: 'p4diagram1', src: require('../images/p4diagram1.png') }, //397x356
    { name: 'p4diagram2', src: require('../images/p4diagram2.png') }, //991x367
    { name: 'p4diagram3', src: require('../images/p4diagram3.png') }, //315x199
    { name: 'p4diagram4', src: require('../images/p4diagram4.png') }, //256x253
    //p5
    { name: 'p5diagram1', src: require('../images/p5diagram1.png') }, //397x328
    { name: 'p5diagram2', src: require('../images/p5diagram2.png') }, //924x627
    //p6
    { name: 'gradeDiagrams', src: require('../images/grade-diagrams.png') }, //990x274
    { name: 'p6diagram2', src: require('../images/p6diagram2.png') }, //1149x252
    //p7 - repeat of 4
    //p8 - repeat of 5
    { name: 'p8diagram1', src: require('../images/p8diagram1.png') }, //536x349


    //p9
    { name: 'p9diagram1', src: require('../images/p9diagram1.png') }, //655x370
    { name: 'p9diagram2', src: require('../images/p9diagram2.png') }, //1072x761
    //p10
    { name: 'p10diagram1', src: require('../images/p10diagram1.png') }, //624x403
    { name: 'p10diagram2', src: require('../images/p10diagram2.png') }, //682x732
    { name: 'p10diagram3', src: require('../images/p10diagram3.png') }, //327x193
    { name: 'p10diagram4', src: require('../images/p10diagram4.png') }, //325x205
    //p11
    { name: 'p11diagram1', src: require('../images/p11diagram1.png') }, //541x1269
    //p12
    { name: 'p12diagram', src: require('../images/p12diagram.png') }, //680x1405
    //p13
    { name: 'p13diagram1', src: require('../images/p13diagram1.png') }, //745x411
    { name: 'p13diagram2', src: require('../images/p13diagram2.png') }, //1032x364
    { name: 'p13diagram3', src: require('../images/p13diagram3.png') }, //690x491
    //p14
    { name: 'p14diagram', src: require('../images/p14diagram.png') }, //687x1338
];

let pageNum = 1;

export class StairCalcResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfButtonText: 'Download PDF'
        };

        this.init();
    };

    init() {
        let that = this;
        pdfImages.forEach(function (o, i) {
            that.toDataURL(o.src, function (dataUrl) {
                o.data = dataUrl;
            });
        });
    }

    getImageData(name) {
        let o = pdfImages.find(function (obj) { return obj.name === name; });
        if (o) return o.data;
        else return;
    }

    addImage(doc, imageName, x, y) {

    }

    handlePdfClick(e) {
        this.setState({ pdfButtonText: 'Building PDF...' });

        let that = this;
        setTimeout(function () {
            that.generatePDF();
        }, 500);
    }

    handleFixedRiseRunPdfClick(e) {
        //this.setState({ pdfButtonText: 'Building PDF...' });

        let that = this;
        setTimeout(function () {
            that.generatePDF();
        }, 500);
    }

    handleShareClick(e) {
        this.props.handleShareClick(e); 
    }

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    centerPos(pageWidth, objectWidth) {
        //console.log(pageWidth, objectWidth)
        return ((pageWidth - objectWidth) / 2);
    };

    getFraction(val) {
        //console.log('getFraction', val);
        if (val) {
            var dec,
                rem,
                parts = val.toString().split('.');

            if (parts.length === 2) {
                dec = (val % 1).toFixed(2);
                rem = parseFloat(dec) * 16;
                if (Math.round(rem) > 0) {
                    let reduced = this.reduce(Math.round(rem), 16)

                    if (reduced[0] === reduced[1]) //sometimes x 1/1 was being returned.
                        return parts[0] + 1;

                    return (parts[0] + ' ' + reduced[0] + '/' + reduced[1]);
                }
                else
                    return parts[0];
            }
            else
                return val.toString();
        }

        return '';
    };

    reduce(numerator, denominator) {
        //return [numerator, denominator]; //bypass reducing for now!  Client wants all fractions as 32nds.

        var gcd = function gcd(a, b) {
           return b ? gcd(b, a % b) : a;
        };
        gcd = gcd(numerator, denominator);
        return [numerator / gcd, denominator / gcd];
    };

    generatePDF = () => {
        let data = this.props.pdfData;
        let date = new Date();
        let doc = new jsPDF('p', 'pt');
        let settings = {
            width: doc.internal.pageSize.getWidth(),
            height: doc.internal.pageSize.getHeight()
        };

        //console.log(doc.getFontList());
        //console.log('PDF DATA: ', this.props.pdfData);

        doc.setFontSize(18);

        //this.introPage(doc, settings); //client requested that this page be removed.
        this.page1(doc, settings);

        //Landing vs Grade pages in PDF
        if ([1, 3].includes(data.configuration)) // landing: pages 2-5
        {
            this.page2(doc, settings);
            this.page3(doc, settings);
            this.page4(doc, settings);
            this.page5(doc, settings);
        }
        else if ([0, 2].includes(data.configuration)) // grade: pages 6-9
        {
            this.page6(doc, settings); //pg 2
            this.page7(doc, settings); //pg 3
            this.page8(doc, settings); //pg 4
            this.page9(doc, settings); //pg 5
            this.page10(doc, settings); //pg 6
        }

        // pages 7-9
        
        //this.page11(doc, settings); // fixed rise/run (not needed with calc according to Rob)

        /*
        REMOVED ON 5/20/24 per Rob's instructions about removing 2 stringer stair info

        this.page12(doc, settings);  // 10,12-14 are for all configs
        this.page13(doc, settings);
        this.page14(doc, settings);
        */


        doc.setFont('helvetica');
        doc.save('fortress-stair-calc_' + date.yyyymmdd() + '.pdf');

        let that = this; // and a wiffle ball bat!
        this.setState({
            pdfButtonText: 'Complete!'
        }, () => {
            setTimeout(function () {
                that.setState({
                    pdfButtonText: 'Download PDF'
                });
            }, 2000);
        });

        pageNum = 1;
    };

    generateFixedRiseRunPDF = () => {
        let data = this.props.pdfData;
        let date = new Date();
        let doc = new jsPDF('p', 'pt');
        let settings = {
            width: doc.internal.pageSize.getWidth(),
            height: doc.internal.pageSize.getHeight()
        };

        //console.log(doc.getFontList());
        //console.log('PDF DATA: ', this.props.pdfData);

        doc.setFontSize(18);

        this.page11(doc, settings); // 11,13-15 are for all configs
        //this.page12(doc, settings); // fixed rise/run (not needed with calc according to Rob)
        this.page13(doc, settings);
        this.page14(doc, settings);
        this.page15(doc, settings);


        doc.setFont('helvetica');
        doc.save('fortress-stair-calc_' + date.yyyymmdd() + '.pdf');

        let that = this; // and a wiffle ball bat!
        this.setState({
            pdfButtonText: 'Complete!'
        }, () => {
            setTimeout(function () {
                that.setState({
                    pdfButtonText: 'Download PDF'
                });
            }, 2000);
        });

        pageNum = 1;
    };

    //****************************************
    // Intro Page Content
    //****************************************
    introPage = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        this.addOutline(doc);
        
        //images
        doc.addImage(this.getImageData('evolutionLogo'), 'PNG', this.centerPos(width, 414), 80, 414, 108)
        doc.addImage(this.getImageData('framingLogo'), 'PNG', this.centerPos(width, 220), 220, 220, 87)

        // title
        let titleY = 400;
        let title = 'Installation Instructions for Fortress Framing Evolution Steel Stair System';
        let lines = doc.setFontSize(22)
            .setFont('bold')
            .splitTextToSize(title, 400)
        doc.text(width / 2, titleY, lines, { align: 'center', lineHeightFactor: 2 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 1
    //****************************************
    page1 = (doc, settings) => {
        let data = this.props.pdfData;
        let width = settings.width;
        let height = settings.height;

        //doc.addPage();

        this.addOutline(doc);

        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Stair Calculator Summary', { align: 'center', lineHeightFactor: 2 })

        // configuration
        doc.setFontSize(16).setFont('bold');
        let configY = 100;
        doc.text(55, configY, "Configuration");
        doc.setFontSize(14).setFont('normal');

        let config = 'Flush to Grade'
        if (data.configuration === 1) config = 'Flush to Landing';
        else if (data.configuration === 2) config = 'Drop to Grade';
        else if (data.configuration === 3) config = 'Drop to Landing';

        doc.text(55, configY + 20, config);

        // input data
        doc.setFontSize(16).setFont('bold');
        let inputY = configY + 60;
        doc.text(55, inputY, "Input");

        // col 1
        doc.setFontSize(14).setFont('normal');
        doc.text(55, inputY + 20, 'Total Rise: ' + data.totalRiseFt + "' " + (data.totalRiseIn > 0 ? (data.totalRiseIn + '"') : ''));
        doc.text(55, inputY + 40, 'Tread Depth: ' + data.treadDepthIn + '"');
        if (data.configuration === 0 || data.configuration === 2)
            doc.text(55, inputY + 60, 'Tread Thickness: ' + data.treadThicknessIn + '"');

        // col 2
        doc.text(250, inputY + 20, 'Tray: ' + (data.tray === 0 ? 'None' : 'Fortress Tray'));
        doc.text(250, inputY + 40, 'Stringer: ' + (data.stringer === 0 ? '2" x 6" Joist as Stringer' : '2" x 11" Beam as Stringer'));
        doc.text(250, inputY + 60, 'Steps: ' + data.stepText);

        // ****************************************
        // results
        doc.setFontSize(16).setFont('bold');
        let resultsY = 260;
        doc.text(55, resultsY, "Results");
        
        //col 1
        doc.setFontSize(14).setFont('normal');
        doc.text(55, resultsY + 20, 'Step Rise: ' + this.getFraction(data.stepRise) + '"');
        doc.text(55, resultsY + 40, 'Bottom Step Rise: ' + this.getFraction(data.bottomStepRise) + '"');
        doc.text(55, resultsY + 60, 'Angle: ' + data.angle);

        //col 2
        doc.text(250, resultsY + 20, 'Bracket Spacing: ' + this.getFraction(data.bracketSpacing) + '"');
        doc.text(250, resultsY + 40, 'Stringer Length: ' + this.getFraction(data.stringerLength) + '"');
        doc.text(250, resultsY + 60, 'Pin Hole Locations: ' + data.pinhole1 + ', ' + data.pinhole2);

        // angles
        doc.setFont('symbol').text(55, resultsY + 100, 'q');
        doc.setFont('helvetica').text(63, resultsY + 100, '1: ' + data.thetaData.theta1.toFixed(2));
        doc.text(55, resultsY + 120, 'X1: ' + this.getFraction(data.thetaData.x1.toFixed(2)) + '"');
        
        if (data.thetaData.theta2) {
            doc.setFont('symbol').text(250, resultsY + 100, 'q');
            doc.setFont('helvetica').text(258, resultsY + 100, '2: ' + data.thetaData.theta2.toFixed(2));
            doc.text(250, resultsY + 120, 'X2: ' + this.getFraction(data.thetaData.x2.toFixed(2)) + '"');
            if (data.stringerLengthDec)
                doc.text(250, resultsY + 140, 'X3: ' + this.getFraction(data.stringerLengthDec.toFixed(2)) + '"');
        } else {
            if (data.stringerLengthDec)
                doc.text(250, resultsY + 100, 'X3: ' + this.getFraction(data.stringerLengthDec.toFixed(2)) + '"');
        }

        if (data.configuration === 1 || data.configuration === 3)
            doc.addImage(this.getImageData('p2diagram2'), 'PNG', this.centerPos(width, 494), resultsY + 170, 494, 101)
        else
            doc.addImage(this.getImageData('p6diagram2'), 'PNG', this.centerPos(width, 495), resultsY + 150, 495, 126)

        doc.addImage(this.getImageData('bracketHoleLocations'), 'PNG', this.centerPos(width, 300), height-300, 300, 190)


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 2
    //****************************************
    page2 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage().setFont('helvetica')

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Cutting the Stringer', { align: 'center', lineHeightFactor: 2 });

        // diagram 3 copy
        let p3CopyLines = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('The stringer can be cut and assembled using the Evolution 2x6 Joist or the Evolution 2x11 beam. The instructions below will work with either option. This instruction is for the Flush to Landing and Drop to Landing installations.', 500)
        doc.text(50, 85, p3CopyLines, { align: 'left', lineHeightFactor: 2 })

        // landing diagrams
        doc.addImage(this.getImageData('landingDiagrams'), 'PNG', this.centerPos(width, 405.5), 160, 405.5, 113)

        // callout
        doc.setDrawColor(255, 7, 7)
            .setLineWidth(2)
            .rect((width / 2) - 150, 310, 300, 75);

        doc.setDrawColor(255, 7, 7)
            .setLineWidth(1)
            .rect((width / 2) - 147, 313, 294, 69)

        // callout copy
        let p3calloutCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('INSTALLATION ALERT: This installation step is ONLY for the Flush to Landing and Drop to Landing configuration (see above)', 280)
        doc.text((width / 2) - 140, 330, p3calloutCopy, { align: 'left', lineHeightFactor: 2 })

        // stringer diagram
        doc.addImage(this.getImageData('p2diagram2'), 'PNG', this.centerPos(width, 494), 420, 494, 101)

        // diagram copy
        let p2diagramCopy1 = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('First, measure X3 for overall length and make the cut. Second, cut the angles using   1 OR by measuring X1 lengths.', 350)
        doc.text((width/2)-174, 560, p2diagramCopy1, { align: 'left', lineHeightFactor: 2 })

        let p2diagramCopy2 = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('  1,  2, X1, and X3 are all calculated using the Fortress Adjustable Bracket Stair Calculator.', 350)
        doc.text((width/2)-173, 620, p2diagramCopy2, { align: 'left', lineHeightFactor: 2 })

        // theta symbols in copy
        doc.setFont('symbol')
            .text((width / 2) - 63, 584, 'q')
            .text((width / 2) - 172, 620, 'q')
            .text((width / 2) - 156, 620, 'q');

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 3
    //****************************************
    page3 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage().setFont('helvetica')

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Adjustable Stair Bracket Hole Locations', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Landing & Drop to Landing', { align: 'center', lineHeightFactor: 2 });

        // hole bracket locations diagram
        doc.addImage(this.getImageData('bracketHoleLocations'), 'PNG', this.centerPos(width, 505), 120, 505, 320)

        // copy
        let p3copyLines1 = doc.setFontSize(12)
            .splitTextToSize('The Adjustable Bracket hole locations are determined using the Fortress Adjustable Bracket Stair Calculator.', 500)
        doc.text(50, height - 260, p3copyLines1, { align: 'left', lineHeightFactor: 2 })


        let p3copyLines2 = doc.setFontSize(12)
            .splitTextToSize('Once the hole locations are known, insert the nylon pins thru the holes. 2 nylon pins come with each adjustable stair bracket.', 500)
        doc.text(50, height - 200, p3copyLines2, { align: 'left', lineHeightFactor: 2 })


        let p3copyLines3 = doc.setFontSize(12)
            .splitTextToSize('The pins in the holes will hold the bracket at the correct angle until it is fastened securely to the stringer. Once the bracket is fastened the pins should be removed and discarded.', 500)
        doc.text(50, height - 140, p3copyLines3, { align: 'left', lineHeightFactor: 2 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 4
    //****************************************
    page4 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Adjustable Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Landing & Drop to Landing', { align: 'center', lineHeightFactor: 2 });

        // diagram 1
        doc.addImage(this.getImageData('p4diagram1'), 'PNG', this.centerPos(width, 198.5), 105, 198.5, 178)

        // diagram 1 copy
        let p4diagram1CopyLines = doc.setFontSize(8)
            .splitTextToSize('Start on the left side (top step) of the stringer. This is the side that in most cases will attach to the deck structure.', 140)
        doc.text(width - 235, 135, p4diagram1CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 2 copy
        let p4diagram2CopyLines = doc.setFontSize(8)
            .splitTextToSize('Measure exactly as shown below paying close attention to the starting and ending measuring points. The distance measured comes from the Fortress Adjustable Bracket Stair Calculator.', 240)
        doc.text((width / 2) - 100, 310, p4diagram2CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 2
        doc.addImage(this.getImageData('p4diagram2'), 'PNG', this.centerPos(width, 495.5), 360, 495.5, 183.5)


        // diagram 3
        doc.addImage(this.getImageData('p4diagram3'), 'PNG', this.centerPos(width, 157.5) - 175, 585, 157.5, 99.5)

        // diagram 3 copy
        let p3diagram3CopyLines = doc.setFontSize(8)
            .splitTextToSize('Once the bracket is located clamp it down tightly. Not doing so will allow for move-ment as you fasten it in place.', 170)
        doc.text(50, height - 145, p3diagram3CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 4
        doc.addImage(this.getImageData('p4diagram4'), 'PNG', this.centerPos(width, 128) + 120, 555, 128, 126.5)

        // diagram 4 copy
        let p3diagram4CopyLines = doc.setFontSize(8)
            .splitTextToSize('With the bracket clamped, use the Evolution Self-Drilling screw to fasten the bracket using the (4) holes shown above. This needs to be repeated on the oppo-site side. Remove the 2 Pins and discard.', 190)
        doc.text(width - 265, height - 155, p3diagram4CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 5
    //****************************************
    page5 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);


        // page title/subtitle/intro
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Adjustable Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Landing & Drop to Landing', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 105, 'You’ll follow this process for the remaining bracket installations.', { align: 'center', lineHeightFactor: 2 });

        // step diagram
        doc.addImage(this.getImageData('p5diagram1'), 'PNG', this.centerPos(width, 198.5), 130, 198.5, 164)

        // diagram copy
        let p5diagram1copy = doc.setFontSize(9)
            .splitTextToSize('Measure exactly as shown below paying close attention to the starting and ending measuring points. The distance measured come from the Fortress Adjustable Bracket Stair Calculator.', 250)
        doc.text((width / 2) - 125, 310, p5diagram1copy, { align: 'left', lineHeightFactor: 1.5 })

        // three circle diagram
        doc.addImage(this.getImageData('p5diagram2'), 'PNG', this.centerPos(width, 462), 380, 462, 313.5)

        // three circle diagram copy
        let p5diagram2copy = doc.setFontSize(9)
            .splitTextToSize('Clamp the bracket to hold it in place and use the Evolution Self-Drilling Screws to fasten the bracket (Qty (4) on each side of bracket for a total of (8))', 350)
        doc.text((width / 2) - 175, height - 120, p5diagram2copy, { align: 'left', lineHeightFactor: 2 })


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 6
    //****************************************
    page6 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Cutting the Stringer', { align: 'center', lineHeightFactor: 2 });

        // diagram 3 copy
        let p3CopyLines = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('The stringer can be cut and assembled using the Evolution 2x6 Joist or the Evolution 2x11 beam. The instructions below will work with either option. This instruction is for the Flush to Grade and Drop to Grade installations.', 500)
        doc.text(50, 85, p3CopyLines, { align: 'left', lineHeightFactor: 2 })

        // landing diagrams
        doc.addImage(this.getImageData('gradeDiagrams'), 'PNG', this.centerPos(width, 405.5), 160, 405.5, 113)

        // callout
        doc.setDrawColor(255, 7, 7)
            .setLineWidth(2)
            .rect((width / 2) - 150, 290, 300, 75);

        doc.setDrawColor(255, 7, 7)
            .setLineWidth(1)
            .rect((width / 2) - 147, 293, 294, 69)


        let p7calloutCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('INSTALLATION ALERT: This installation step is ONLY for the Flush to Grade and Drop to Grade configuration (see above)', 280)
        doc.text((width / 2) - 140, 320, p7calloutCopy, { align: 'left', lineHeightFactor: 2 })


        // stringer diagram
        doc.addImage(this.getImageData('p6diagram2'), 'PNG', this.centerPos(width, 494), 390, 484, 101)

        let p6diagramCopy1 = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('  1,  2, X1, X2, and X3 are all calculated using the Fortress Adjustable Bracket Stair Calculator.', 500)
        doc.text((width / 2) - 250, 525, p6diagramCopy1, { align: 'left', lineHeightFactor: 2 })

        let p6diagramCopy2 = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('Cut stringer as shown. First, measure X3 for overall length and make the cut. Second, cut the angles using   1 OR by measuring X1 lengths.', 500)
        doc.text((width / 2) - 250, 575, p6diagramCopy2, { align: 'left', lineHeightFactor: 2 })

        let p6diagramCopy3 = doc.setFontSize(12).setFont('normal')
            .splitTextToSize('Mark the   2 angle using a silver permanent marker but DO NOT cut the   2 angle during this step.', 500)
        doc.text((width / 2) - 250, 635, p6diagramCopy3, { align: 'left', lineHeightFactor: 2 })

        
        doc.setFont('symbol')
            .text(48, 525, 'q')
            .text(64, 525, 'q')
            .text(119, 599, 'q')
            .text(98, 635, 'q')
            .text((width / 2) + 134, 635, 'q');

        //text underline
        doc.setLineWidth(1)
            .setDrawColor(0, 0, 0)
            .line((width / 2)+44, 638, (width / 2) + 90, 638)


        // callout2
        doc.setDrawColor(255, 7, 7)
            .setLineWidth(2)
            .rect((width / 2) - 150, height-180, 300, 75);

        doc.setDrawColor(255, 7, 7)
            .setLineWidth(1)
            .rect((width / 2) - 147, height-177, 294, 69)


        let p3calloutCopy = doc.setFont('helvetica').setFontSize(10).setFont('normal')
            .splitTextToSize('INSTALLATION ALERT: DO NOT cut the   2 during this step.  2 will be cut during a later step.', 280)
        doc.text((width / 2) - 140, height-150, p3calloutCopy, { align: 'left', lineHeightFactor: 2 })


        doc.setFont('symbol')
            .text((width / 2) + 46, height-150, 'q')
            .text((width / 2) + 131, height-150, 'q');


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 7
    //****************************************
    page7 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Adjustable Stair Bracket Hole Locations', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Grade & Drop to Grade', { align: 'center', lineHeightFactor: 2 });

        // hole bracket locations diagram
        doc.addImage(this.getImageData('bracketHoleLocations'), 'PNG', this.centerPos(width, 505), 120, 505, 320)

        // copy
        let p4copyLines1 = doc.setFontSize(12)
            .splitTextToSize('The Adjustable Bracket hole locations are determined using the Fortress Adjustable Bracket Stair Calculator.', 500)
        doc.text(50, height - 260, p4copyLines1, { align: 'left', lineHeightFactor: 2 })


        let p4copyLines2 = doc.setFontSize(12)
            .splitTextToSize('Once the hole locations are known, insert the nylon pins thru the holes. 2 nylon pins come with each adjustable stair bracket.', 500)
        doc.text(50, height - 200, p4copyLines2, { align: 'left', lineHeightFactor: 2 })


        let p4copyLines3 = doc.setFontSize(12)
            .splitTextToSize('The pins in the holes will hold the bracket at the correct angle until it is fastened securely to the stringer. Once the bracket is fastened the pins should be removed and discarded.', 500)
        doc.text(50, height - 140, p4copyLines3, { align: 'left', lineHeightFactor: 2 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 8
    //****************************************
    page8 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Adjustable Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Grade & Drop to Grade', { align: 'center', lineHeightFactor: 2 });

        // diagram 1
        doc.addImage(this.getImageData('p8diagram1'), 'PNG', this.centerPos(width, 268), 105, 268, 174.5)

        // diagram 1 copy
        let p8diagram1CopyLines = doc.setFontSize(9)
            .splitTextToSize('Start on the left side (top step) of the stringer. This is the side that in most cases will attach to the deck structure.', 140)
        doc.text(width - 240, 135, p8diagram1CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 2 copy
        let p8diagram2CopyLines = doc.setFontSize(9)
            .splitTextToSize('Measure exactly as shown below paying close attention to the starting and ending measuring points. The distance measured comes from the Fortress Adjustable Bracket Stair Calculator.', 240)
        doc.text((width / 2) - 100, 310, p8diagram2CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 2
        doc.addImage(this.getImageData('p4diagram2'), 'PNG', this.centerPos(width, 495.5), 360, 495.5, 183.5)


        // diagram 3
        doc.addImage(this.getImageData('p4diagram3'), 'PNG', this.centerPos(width, 157.5) - 175, 585, 157.5, 99.5)

        // diagram 3 copy
        let p8diagram3CopyLines = doc.setFontSize(9)
            .splitTextToSize('Once the bracket is located clamp it down tightly. Not doing so will allow for move-ment as you fasten it in place.', 170)
        doc.text(50, height - 145, p8diagram3CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 4
        doc.addImage(this.getImageData('p4diagram4'), 'PNG', this.centerPos(width, 128) + 120, 555, 128, 126.5)

        // diagram 4 copy
        let p8diagram4CopyLines = doc.setFontSize(9)
            .splitTextToSize('With the bracket clamped, use the Evolution Self-Drilling screw to fasten the bracket using the (4) holes shown above. This needs to be repeated on the opposite side. Remove the 2 Pins and discard.', 190)
        doc.text(width - 265, height - 155, p8diagram4CopyLines, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 9
    //****************************************
    page9 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Adjustable Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Grade & Drop to Grade', { align: 'center', lineHeightFactor: 2 });

        doc.text(width / 2, 105, 'You’ll follow this process for the remaining bracket installations except the last step.', { align: 'center', lineHeightFactor: 2 })

        // diagram 1
        doc.addImage(this.getImageData('p9diagram1'), 'PNG', this.centerPos(width, 327.5), 115, 327.5, 185);

        // diagram1 copy
        let p9diagram1Copy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('DO NOT fasten the bottom step yet', 100);
        doc.text(width-120, 240, p9diagram1Copy, { align: 'left', lineHeightFactor: 2 });

        // diagram2 copy
        let p9diagram2Copy = doc.splitTextToSize('Measure exactly as shown below paying close attention to the starting and ending measuring points. The distance measured comes from the Fortress Adjustable Bracket Stair Calculator.', 300);
        doc.text((width / 2) - 150, 320, p9diagram2Copy, { align: 'left', lineHeightFactor: 2 });

        // diagram 2
        doc.addImage(this.getImageData('p9diagram2'), 'PNG', this.centerPos(width, 536), 370, 498.6, 304.4);

        // diagram2 copy
        let p9diagram2BottomCopy = doc.splitTextToSize('Clamp the bracket to hold it in place and use the Evolution Self-Drilling Screws to fasten the bracket (Qty (4) on each side of bracket for a total of (8))', 500);
        doc.text((width / 2) - 250, height-140, p9diagram2BottomCopy, { align: 'left', lineHeightFactor: 2 });

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 10
    //****************************************
    page10 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Adjustable Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        doc.setFontSize(12).setFont('normal')
            .text(width / 2, 80, 'Flush to Grade & Drop to Grade', { align: 'center', lineHeightFactor: 2 });

        // diagram 1
        doc.addImage(this.getImageData('p10diagram1'), 'PNG', this.centerPos(width, 327.5), 105, 327.5, 185);

        // diagram1 copy
        let p10diagram1Copy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The last bracket (bottom step) will overhang the stringer and will require some trimming.', 200);
        doc.text(width - 230, 130, p10diagram1Copy, { align: 'left', lineHeightFactor: 2 });

        // diagram 2
        doc.addImage(this.getImageData('p10diagram2'), 'PNG', 25, 305, 307, 329.4);

        // diagram 2 copy
        let p10diagram2Copy1 = doc.splitTextToSize('Simultaneously measure the bracket spacing AND make sure the bracket is square.', 200);
        doc.text(width - 240, 320, p10diagram2Copy1, { align: 'left', lineHeightFactor: 2 });

        let p10diagram2Copy2 = doc.splitTextToSize('Once the bracket is in position use a clamp to secure it.', 200);
        doc.text(width - 240, 370, p10diagram2Copy2, { align: 'left', lineHeightFactor: 2 });

        let p10diagram2Copy3 = doc.splitTextToSize('Fasten the bracket using the Evolution Self-Drilling screws.', 200);
        doc.text(width - 240, 430, p10diagram2Copy3, { align: 'left', lineHeightFactor: 2 });

        let p10diagram2Copy4 = doc.splitTextToSize('You’ll see the line you drew earlier when you cut the stringer.', 200);
        doc.text(width - 240, 490, p10diagram2Copy4, { align: 'left', lineHeightFactor: 2 });

        let p10diagram2Copy5 = doc.splitTextToSize('Note that the pin will not touch the stringer on the bottom but the top pin should still be used.', 200);
        doc.text(width - 240, 550, p10diagram2Copy5, { align: 'left', lineHeightFactor: 2 });

        // diagram 3
        doc.addImage(this.getImageData('p10diagram3'), 'PNG', (width/2)-150, height-190, 163.5, 96.5);

        // diagram 3 copy
        let p10diagram3Copy = doc.setFontSize(9).setFont('normal').splitTextToSize('Use a straight edge and permanent marker to draw that line straight across the length of the bracket.', 120);
        doc.text(30, height - 160, p10diagram3Copy, { align: 'left', lineHeightFactor: 2 });

        // diagram 4
        doc.addImage(this.getImageData('p10diagram4'), 'PNG', width-190, height - 208, 162.5, 102.5);

        // diagram 4 copy
        let p10diagram4Copy = doc.splitTextToSize('Make a cut across the line to finish out the stringer', 100);
        doc.text((width/2)+25, height - 165, p10diagram4Copy, { align: 'left', lineHeightFactor: 2 });


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 11
    //****************************************
    page11 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 55, 'Installing the Fixed Rise Run Stair Brackets', { align: 'center', lineHeightFactor: 2 });

        let p12introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The fixed Rise Run brackets are non - adjustable, and are to be used for stair rise / run of either 7”/11” or 7.75”/11”.', 560)
        doc.text((width / 2), 80, p12introCopy, { align: 'center', lineHeightFactor: 2 })

        doc.addImage(this.getImageData('p11diagram1'), 'PNG', 50, 90, 270.5, 669)

        // diagram 2 copy
        let p11diagramCopy1 = doc.splitTextToSize('Install the brackets on either an Evolution 2”x6” Joist or 2” x11” Beam.', 200);
        doc.text(width - 240, 120, p11diagramCopy1, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy2 = doc.splitTextToSize('Butt the brackets together; they should be touching when they’re fastened.', 200);
        doc.text(width - 240, 180, p11diagramCopy2, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy3 = doc.splitTextToSize('Fasten the brackets to the joists using the Evolution 3/4” Self-Drilling Screw.', 200);
        doc.text(width - 240, 240, p11diagramCopy3, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy4 = doc.splitTextToSize('Once all the brackets are installed use a silver permanent marker to mark the angles.', 200);
        doc.text(width - 240, 350, p11diagramCopy4, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy5 = doc.splitTextToSize('The angle does not have to be measured. Use a straight edge to continue angles on either side of the bracket.', 200);
        doc.text(width - 240, 410, p11diagramCopy5, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy6 = doc.splitTextToSize('Use a metal cutting saw and blade to make the marked cuts.', 200);
        doc.text(width - 240, 600, p11diagramCopy6, { align: 'left', lineHeightFactor: 2 });

        let p11diagramCopy7 = doc.splitTextToSize('Install the assembled stringers using the Evolution Stair Strap and Evolution Anchor Bracket.', 200);
        doc.text(width - 240, 660, p11diagramCopy7, { align: 'left', lineHeightFactor: 2 });

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 12
    //****************************************
    page12 = (doc, settings) => {
        let width = settings.width;
        //let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Stair Strap', { align: 'center', lineHeightFactor: 2 });

        doc.addImage(this.getImageData('p12diagram'), 'PNG', 40, 90, 272, 562)

        let p12diagramCopy1 = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('Position the stair strap and fasten to the deck using the Evolution 3/4” Self-Drilling Screws', 170)
        doc.text(width - 250, 130, p12diagramCopy1, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy2 = doc.splitTextToSize('Install the Stringer by positioning it on the stair strap and fastening it using the holes on the side of the bracket and Evolution 3/4” Self-Drilling Screw (Not Pictured).', 170)
        doc.text(width - 250, 190, p12diagramCopy2, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy3 = doc.splitTextToSize('Once the Stringer is installed, use a rubber mallet to bend the bottom portion of the stair strap along the bottom side of the stringer.', 170)
        doc.text(width - 250, 310, p12diagramCopy3, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy4 = doc.splitTextToSize('Fill in all the holes on the bracket with the Evolution 3/4” Self-Drilling Screw.', 170)
        doc.text(width - 250, 390, p12diagramCopy4, { align: 'left', lineHeightFactor: 1.5 })

        let p12diagramCopy5 = doc.splitTextToSize('Stair Strap fully installed.', 170)
        doc.text(width - 250, 560, p12diagramCopy5, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 13
    //****************************************
    page13 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Anchor Bracket', { align: 'center', lineHeightFactor: 2 });

        let p14introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The Evolution Anchor Bracket attaches the stringer to the concrete footing pad or the deck landing (typical installation).', 560)
        doc.text(width / 2, 80, p14introCopy, { align: 'center', lineHeightFactor: 2 })

        // diagram 1
        doc.addImage(this.getImageData('p13diagram1'), 'PNG', 40, 120, 372.5, 205.5)

        let p13diagram1Copy = doc.splitTextToSize('Attached the anchor bracket to the bottom of the stringer using the Evolution 3/4” Self-Drilling Screws.', 150)
        doc.text(width - 175, 190, p13diagram1Copy, { align: 'left', lineHeightFactor: 1.5 })
        
        // diagram 2
        doc.addImage(this.getImageData('p13diagram2'), 'PNG', this.centerPos(width, 516), 325, 516, 182)

        let p13diagram2Copy = doc.splitTextToSize('Use a 3/8” concrete wedge anchor to securely fasten the anchor bracket to the concrete.', 150)
        doc.text((width/2)-140, 390, p13diagram2Copy, { align: 'left', lineHeightFactor: 1.5 })

        // diagram 3
        doc.addImage(this.getImageData('p13diagram3'), 'PNG', 40, 500, 345, 245.5)

        let p13diagram3Copy = doc.splitTextToSize('If anchoring to a landing, install blocking and attach the anchor bracket thru the blocking using 3/8” bolts, nuts, and washers.', 150)
        doc.text(width - 195, 600, p13diagram3Copy, { align: 'left', lineHeightFactor: 1.5 })


        this.addFooter(doc);
        pageNum++;
    };

    //****************************************
    // Page 14
    //****************************************
    page14 = (doc, settings) => {
        let width = settings.width;
        let height = settings.height;

        doc.addPage()

        this.addOutline(doc);

        // page title
        doc.setFontSize(22).setFont('bold')
            .text(width / 2, 65, 'Stair Tray', { align: 'center', lineHeightFactor: 2 });

        let p15introCopy = doc.setFontSize(10).setFont('normal')
            .splitTextToSize('The stair trays are 48” wide with blocking spaced every 10” On-Center. The tray can span 48” between stringers in residential applications.', 500)
        doc.text((width/2) - 250, 80, p15introCopy, { align: 'left', lineHeightFactor: 2 })

        doc.addImage(this.getImageData('p14diagram'), 'PNG', 50, 115, 274.8, 535.2)

        let p14diagramCopy1 = doc.splitTextToSize('Stringers full assembled and anchored to the deck with the Evolution Stair Strap and anchored to the concrete with the Evolution Anchor Bracket.', 150)
        doc.text(width - 250, 175, p14diagramCopy1, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy2 = doc.splitTextToSize('Once the stringers are attached lay the Stair Tray on top of the stair brackets and center it.', 150)
        doc.text(width - 250, 340, p14diagramCopy2, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy3 = doc.splitTextToSize('Fasten the tray using (2) Evolution 3/4” Self Drilling Screws at each stringer.', 150)
        doc.text(width - 250, 420, p14diagramCopy3, { align: 'left', lineHeightFactor: 1.5 })

        let p14diagramCopy4 = doc.splitTextToSize('The blocking every 10” On-Center can be used for railing post attach-ment and stair tread attachment.', 150)
        doc.text(width - 250, 540, p14diagramCopy4, { align: 'left', lineHeightFactor: 1.5 })

        this.addFooter(doc);
        pageNum++;
    };

    // Page Outline
    addOutline = (doc) => {
        doc.setDrawColor(0, 102, 153) //dark blue
            .setLineWidth(2)
            .rect(10, 11, 583, 750);

        doc.setDrawColor(153, 194, 214) //light blue
            .setLineWidth(1)
            .rect(20, 20, 564, 730)
    };

    // Page Footer
    addFooter = (doc) => {
        doc.setFont('helvetica')

        let date = new Date();
        let copyright = '© 2019 Fortress Framing, LLC';
        let revision = 'Rev-' + date.yyyymmdd();

        // footer
        doc.setFontSize(10);
        doc.setFont('normal')
        doc.text(20, 775, pageNum.toString());

        doc.text(220, 775, copyright);
        doc.text(510, 775, revision);

        //console.log('Page ' + pageNum.toString() + ' built!');
    };

    render() {
        return (
            <div>
                <div className={this.props.visible ? '' : 'hide'}>
                    <h5>Results</h5>
                    <div className="card col-sm-12 col-md-9 bg-light mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="input-group">
                                        <label className="mr-2">Step Rise:</label>
                                        <strong>{this.props.stepRise}"</strong>
                                    </div>
                                    <div className={'form-group ' + ((this.props.configuration === 1 || this.props.configuration === 3) ? 'hide' : '')}>
                                        <label className="mr-2">Bottom Step Rise:</label>
                                        <strong>{this.props.bottomStepRise}"</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">Angle:</label>
                                        <strong>{this.props.angle}</strong>
                                    </div>
                                    <div className={'form-group ' + ((this.props.configuration === 1 || this.props.configuration === 3) ? '' : 'hide')}>
                                        <label className="mr-2">&nbsp;</label>
                                        <strong>&nbsp;</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">&theta;1:</label>
                                        <strong>{this.props.theta1}</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">X1:</label>
                                        <strong>{this.props.x1}"</strong>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="mr-2">Bracket Spacing:</label>
                                        <strong>{this.props.bracketSpacing}"</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">Stringer Length:</label>
                                        <strong>{this.props.stringerLength}"</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">Pin Hole Locations:</label>
                                        <strong>{this.props.pinholeLocations}</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">&theta;2:</label>
                                        <strong>{this.props.theta2}</strong>
                                    </div>
                                    <div className={'form-group ' + ((this.props.configuration === 1 || this.props.configuration === 3) ? 'hide' : '')}>
                                        <label className="mr-2">X2:</label>
                                        <strong>{this.props.x2}"</strong>
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2">X3:</label>
                                        <strong>{this.props.stringerLength}"</strong>
                                    </div>
                                </div>
                                <div className="col text-right">
                                    <button type="button" className="btn btn-lg btn-outline-secondary" onClick={(e) => this.handlePdfClick(e)} disabled={this.state.pdfButtonText !== 'Download PDF'}><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;{this.state.pdfButtonText}</button>&nbsp;&nbsp;
                                </div>
                            </div>

                            <div className="alert alert-warning mt-3" role="alert">
                                <em>* Subject to local code restrictions and/or engineering span tables.</em>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                </div>
             </div>
        );
    };
}