import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TreadDepthModal = (props) => {
    const {
        className,
        title
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <button type="button" className="btn btn-link p-0 pb-1" onClick={toggle}><span className="badge rounded-pill bg-secondary">i</span></button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <p>Distance from where the bottom step sits to the top of the deck frame.  The thickness of the deck board IS NOT included in this measurement.</p>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Got it!</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default TreadDepthModal;