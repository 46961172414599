import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import { StairConfigurationItem } from './StairConfigurationItem';
import { RiseAndTread } from './RiseAndTread';
import { TrayAndStringer } from './TrayAndStringer';
import { StepSelect } from './StepSelect';
import { StairCalcResults } from './StairCalcResults';
import { FixedRiseRunPDF } from './FixedRiseRunPDF';
import { Errors } from './Errors';
import HelpModal from './modals/HelpModal';
import ConfigurationModal from './modals/ConfigurationModal';
import Fraction from 'fraction.js';

export class StairCalc extends Component {
    constructor(props) {
        super(props);
        this.arrSteps = [];
        this.stepOptionCount = 0;
        this.stepOpts = [];
        this.arrAngles = [
            [24.32188418, 28.69308748, 32.56331048, 36.40406521, 39.58787357],
            [18.30635063, 23.08261229, 27.36417415, 31.64138205, 35.21629680],
            [11.53637788, 16.66754860, 21.34546996, 26.07172173, 30.06917653],
            [4.69633962, 10.06151376, 15.05053783, 20.16930165, 24.56446035]
        ];
        this.arrMaxRuns = this.populateMaxRuns();
        console.log('arrMaxRuns', this.arrMaxRuns);
        //this.arrTotalRunOpts = [];
        this.arrStringerLengths = []; //only populated for f2l
        this.selectedStepOptionId = 0;

        this.state = {   
            configuration: 0,
            totalRiseFt: 9,
            totalRiseIn: 0,
            treadDepthIn: 11,
            treadThicknessIn: 1,
            //results
            stepRise: 0,
            bottomStepRise: 0,
            angle: 0,
            bracketSpacing: 0,
            stringerLength: 0,
            stepOpts: [],
            tray: 0,
            stringer: 0,
            errors: []
        };

        this.updateSteps(this.state);
        this.state.stepOptionCount = this.stepOptionCount;
        this.state.stepOpts = this.stepOpts;

        this.debug = true;
    }

    radians(degrees) {
        return degrees * (Math.PI / 180);
    };

    populateMaxRuns(){
        return [
            [
                this.getMaxRunCalc(4.92750764, this.arrAngles[0][0]),
                this.getMaxRunCalc(5.70875764, this.arrAngles[0][1]),
                this.getMaxRunCalc(6.49000764, this.arrAngles[0][2]),
                this.getMaxRunCalc(7.26567311, this.arrAngles[0][3]),
                this.getMaxRunCalc(8.04133858, this.arrAngles[0][4])
            ],
            [
                this.getMaxRunCalc(4.92750764, this.arrAngles[1][0]),
                this.getMaxRunCalc(5.70875764, this.arrAngles[1][1]),
                this.getMaxRunCalc(6.49000764, this.arrAngles[1][2]),
                this.getMaxRunCalc(7.26567311, this.arrAngles[1][3]),
                this.getMaxRunCalc(8.04133858, this.arrAngles[1][4])
            ],
            [
                this.getMaxRunCalc(4.92750764, this.arrAngles[2][0]),
                this.getMaxRunCalc(5.70875764, this.arrAngles[2][1]),
                this.getMaxRunCalc(6.49000764, this.arrAngles[2][2]),
                this.getMaxRunCalc(7.26567311, this.arrAngles[2][3]),
                this.getMaxRunCalc(8.04133858, this.arrAngles[2][4])
            ],
            [
                this.getMaxRunCalc(4.92750764, this.arrAngles[3][0]),
                this.getMaxRunCalc(5.70875764, this.arrAngles[3][1]),
                this.getMaxRunCalc(6.49000764, this.arrAngles[3][2]),
                this.getMaxRunCalc(7.26567311, this.arrAngles[3][3]),
                this.getMaxRunCalc(8.04133858, this.arrAngles[3][4])
            ]
        ];
    }

    getMaxRunCalc(dec, angle) {
        //=(4.92750764+(0.127952755*TAN(RADIANS(J6/2))+0.9187478)*TAN(RADIANS(J6)))/TAN(RADIANS(J6))

        return parseFloat(((dec + (0.127952755 * Math.tan(this.radians(angle / 2)) + 0.9187478) * Math.tan(this.radians(angle))) / Math.tan(this.radians(angle))).toFixed(4));
        //return this.roundToTwo((dec + (0.127952755 * Math.tan(this.radians(angle / 2)) + 0.9187478) * Math.tan(this.radians(angle))) / Math.tan(this.radians(angle)));
        //return this.toFixedTrunc((dec + (0.127952755 * Math.tan(this.radians(angle / 2)) + 0.9187478) * Math.tan(this.radians(angle))) / Math.tan(this.radians(angle)), 4);
        //return parseFloat((dec + (0.127952755 * Math.tan(this.radians(angle / 2)) + 0.9187478) * Math.tan(this.radians(angle))) / Math.tan(this.radians(angle)));
    };

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    };

    //toFixedTrunc(num, fixed) {
    //    return num.toFixed(fixed);
    //    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    //    return num.toString().match(re)[0];
    //};

    populateTotalRunOptions() {
        var opts = [];
        var row = [];

        var totalRise = ((this.state.totalRiseFt * 12) + this.state.totalRiseIn);

        //loop through angles for calc.
        for (var i = 0; i < 4; i++) {
            for (var j = 0; j < 5; j++) {
                row.push(totalRise / Math.tan(this.radians(this.arrAngles[i][j]))); //removed this.roundToTwo()
            }
            opts.push(row);
            row = []; //reset.
        }

        return opts;
    };

    ///setup = tray, beams, both, or default
    isSetup(kind) {
        if (this.state.tray === 1 && this.state.stringer === 0) {
            if (kind === 'tray') return true;
        } else if (this.state.tray === 1 && this.state.stringer === 1) {
            if (kind === 'both') return true;
        } else if (this.state.tray === 0 && this.state.stringer === 1) {
            if (kind === 'beams') return true;
        } else {
            if (kind === 'default') return true;
        }

        return false;
    };

    getTotalRun(stepRise, angle) {
        let totalRise = ((this.state.totalRiseFt * 12) + this.state.totalRiseIn);

        console.log('getTotalRun', totalRise, stepRise, angle);

        //f2l - =($C$2*12+$E$2)/TAN(RADIANS(M4))
        //d2g - =IF(E12 = 0, "0", (($C$2 * 12 + $E$2) - $E$5) / TAN(RADIANS(M6)))

        if (this.state.configuration === 0 || this.state.configuration === 1) //flush to landing (1) or grade (0)
        {
            return (totalRise / Math.tan(this.radians(angle)));
        }
        else if (this.state.configuration === 2 || this.state.configuration === 3) //drop to grade (2) or landing (3)
        {
            return ((totalRise - stepRise) / Math.tan(this.radians(angle)));
        }

        return 0;
    };

    getStringerLength(stepVal, stepRise, angle, maxRun) {

        let totalRise = ((this.state.totalRiseFt * 12) + this.state.totalRiseIn);

        //console.log('getStringerLength', totalRise, stepVal, stepRise, angle, maxRun);

        if (this.state.configuration === 0) {//flush to grade (0)
            //there don't seem to be differences between setups for this calc.
            return ((totalRise / Math.sin(this.radians(angle))) - ((maxRun - stepVal) / Math.cos(this.radians(angle))) - (this.state.treadThicknessIn / Math.sin(this.radians(angle))));
        }
        else if (this.state.configuration === 1) //flush to landing (1)
        {
            if (this.isSetup('tray') ||
                this.isSetup('default')) {
                return ((totalRise / Math.sin(this.radians(angle))) + (5.905511811 * Math.tan(this.radians(angle))));
            }
            else if (this.isSetup('both') ||
                this.isSetup('beams')) {
                return ((totalRise / Math.sin(this.radians(angle))) + (11.02362205 * Math.tan(this.radians(angle))));
            }
        }
        else if (this.state.configuration === 2) { //drop to grade (2)
            return (((totalRise - stepRise) / Math.sin(this.radians(angle))) - ((maxRun - stepVal) / Math.cos(this.radians(angle))) - (this.state.treadThicknessIn/Math.sin(this.radians(angle))));
        }
        else if(this.state.configuration === 3) //drop to landing (3)
        {
            if (this.isSetup('default')) {
                return ((totalRise - stepRise) / Math.sin(this.radians(angle)) + (5.905511811 * Math.tan(this.radians(angle))));
            }
            else {
                return (((totalRise - stepRise) / Math.sin(this.radians(angle))) - ((maxRun - stepVal) / Math.cos(this.radians(angle))) - (this.state.treadThicknessIn / Math.sin(this.radians(angle))));
            }
        }

        //console.error('INVALID SETUP');
        return 0;
    };

    getFraction(val) {
      
        if (val) {
            var dec,
                rem,
                parts = val.toString().split('.');

            if (parts.length === 2) {

                var rounded = (Math.ceil(parseFloat('.' + parts[1]) * 16) / 16);
                var f = new Fraction(rounded);

                console.log('foo: ', parts[1], rounded);
                return (parts[0] + ' ' + f.toFraction(true));
                // dec = (val % 1).toFixed(2);

                // var rounded = (Math.round(parseFloat(dec) * 16) / 16).toFixed(3);
                // var f = new Fraction(rounded);
                // var newVal = f.toFraction(true);
                // return newVal;

                /*
                    dec = (val % 1).toFixed(2);
                    rem = parseFloat(dec) * 16;
                    if (Math.round(rem) > 0) {

                        
                        var rounded = (Math.ceil(parseFloat('.' + parts[1]) * 16) / 16);
                        var f = new Fraction(rounded);

                        console.log('foo: ', parts[1], rounded);
                        return (parts[0] + ' ' + f.toFraction(true));


                        let reduced = this.reduce(Math.round(rem), 16)

                        if (reduced[0] === reduced[1]) //sometimes x 1/1 was being returned.
                            return (parseFloat(parts[0]) + 1).toString();

                        return (parts[0] + ' ' + reduced[0] + '/' + reduced[1]);
                    }
                    else
                        return parts[0];
                */
            }
            else
                return val.toString();
        }

        return '';
    };

    reduce(numerator, denominator) {
        //return [numerator, denominator]; //bypass reducing for now!  Client wants all fractions as 32nds.

        var gcd = function gcd(a, b) {
            return b ? gcd(b, a % b) : a;
        };
        gcd = gcd(numerator, denominator);
        return [numerator / gcd, denominator / gcd];
    };

    getStepOptions(rise) {
        //console.log(rise);

        var opts = [];
        var row = [];
        var i, j = 0;

        //loop through angles for calc.
        if (this.state.tray === 1 && this.state.stringer === 0) {
            //console.info('STAIR TRAY CALC');

            for (i = 0; i < 4; i++) {
                for (j = 0; j < 5; j++) {
                    if (this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) < this.state.treadDepthIn &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) > (this.state.treadDepthIn - 1.25) &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) <= 11) { //11 instead of max run number
                        row.push(this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))));
                    }
                    else
                        row.push(0);
                }
                opts.push(row);
                row = []; //reset.
            }
        } else if (this.state.tray === 1 && this.state.stringer === 1) {
            //console.info('BEAMS WITH TRAYS CALC');

            for (i = 0; i < 4; i++) {
                for (j = 0; j < 5; j++) {
                    if (this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) < this.state.treadDepthIn &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) > (this.state.treadDepthIn - 1.25) &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) <= 11) { //11 instead of max run number
                        row.push(this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))));
                    }
                    else
                        row.push(0);
                }
                opts.push(row);
                row = []; //reset.
            }
        } else {
            console.log('getStepOptions', this.state.configuration);

            if (this.state.tray === 0 && this.state.stringer === 1) {
                //console.info('BEAMS CALC');
            } else {
                //console.info('DEFAULT CALC');
            }

            for (i = 0; i < 4; i++) {
                for (j = 0; j < 5; j++) {

                    //d2g   -    =IF(ROUND($E$4/TAN(RADIANS(J5)),2)<$C$3,IF(ROUND($E$4/TAN(RADIANS(J5)),2)>$C$3-1.25,IF(ROUND($E$4/TAN(RADIANS(J5)),2)<=Q5,ROUND($E$4/TAN(RADIANS(J5)),2),0),0),0)

                    //f2l   -    =IF(ROUND($E$4/TAN(RADIANS(J4)),2)<$C$3,IF(ROUND($E$4/TAN(RADIANS(J4)),2)>$C$3-1.25,IF(ROUND($E$4/TAN(RADIANS(J4)),2)<=J11,ROUND($E$4/TAN(RADIANS(J4)),2),0),0),0)


                    if (this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) < this.state.treadDepthIn &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) > (this.state.treadDepthIn - 1.25) &&
                        this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))) <= (this.arrMaxRuns[i][j])) {
                        row.push(this.roundToTwo(rise / Math.tan(this.radians(this.arrAngles[i][j]))));
                    }
                    else
                        row.push(0);
                }
                opts.push(row);
                row = []; //reset.
            }
        }

        //console.log('stepOpts', opts);

        return opts;
    };

    getThetaData(angle) {

        if (this.state.configuration === 1 || this.state.configuration === 3) {//flush or drop to landing
            return {
                'theta1': angle,
                'x1': this.state.stringer === 0 ? (Math.tan(this.radians(angle)) * 150 / 25.4) : (Math.tan(this.radians(angle)) * 278.4 / 25.4), //changes depending on stringer type
                'x2': null,
                'x3': this.state.stringerLengthDec
            };
        }
        else if (this.state.configuration === 0 || this.state.configuration === 2) //flush or drop to grade
        {
            return {
                'theta1': angle,
                'theta2': 90 - angle,
                'x1': this.state.stringer === 0 ? (Math.tan(this.radians(angle)) * 150 / 25.4) : (Math.tan(this.radians(angle)) * 278.4 / 25.4), //changes depending on stringer type
                'x2': this.state.stringer === 0 ? (Math.tan(this.radians(90 - angle)) * 150 / 25.4) : (Math.tan(this.radians(90 - angle)) * 278.4 / 25.4),  //changes depending on stringer type
                //'x3': this.state.stringerLengthDec
            };
        }

        //console.error('INVALID SETUP');
        return 0;
    };

    //Select Configuration Event
    setConfiguration(config) {
        this.reset();

        this.setState({
            configuration: config,
            selectedStepOptionId: 0
        }, () => {
            this.updateSteps(this.state);

            this.setState({
                stepOptionCount: this.stepOptionCount,
                stepOpts: this.stepOpts
            }, () => {
                //this.output(this.state);
            });
        });
    }

    //Inputs Changed
    onInputChange(event) {
        //event.target.checkValidity();

        const target = event.target; 
        const value = target.type === 'checkbox' ? target.checked : ((event.target.type === 'number' || target.type === 'radio') ? parseFloat(target.value) : target.value);
        const name = target.name;

        //reset step select and hide results
        this.setState({
            selectedStepOptionId: 0
        }, () => { });

        if (name !== '' && value !== null) {
            this.reset();

            let arrErr = this.state.errors;

            console.log('input change - errors: ', this.state.errors);

            //clear errors as necessary
            if (name === 'totalRiseFt' && this.hasKey(arrErr, 'totalRiseFt'))
                arrErr.splice(arrErr.findIndex(v => v.key === "totalRiseFt"), 1);

            if (name === 'totalRiseIn' && this.hasKey(arrErr, 'totalRiseIn'))
                arrErr.splice(arrErr.findIndex(v => v.key === "totalRiseIn"), 1);

            if (name === 'treadDepthIn' && this.hasKey(arrErr, 'treadDepth'))
                arrErr.splice(arrErr.findIndex(v => v.key === "treadDepth"), 1);

            if (name === 'treadThicknessIn' && this.hasKey(arrErr, 'treadThickness'))
                arrErr.splice(arrErr.findIndex(v => v.key === "treadThickness"), 1);

            //rise
            if (!this.validateTotalRiseFt((name === 'totalRiseFt') ? value : this.state.totalRiseFt))
                arrErr.push({ key: 'totalRiseFt', msg: "Total rise must be less than 12'" });

            if (!this.validateTotalRiseIn((name === 'totalRiseIn') ? value : this.state.totalRiseIn))
                arrErr = this.addError(arrErr, 'totalRiseIn', "Total rise must be less than 12'");

            //tread depth
            let treadDepthResult = this.validateTreadDepth((name === 'treadDepthIn') ? value : this.state.treadDepthIn);

            if (!treadDepthResult.result) {
                if (treadDepthResult.min)
                    arrErr = this.addError(arrErr, 'treadDepth', 'The tread minimum is ' + treadDepthResult.min + '"');
                else if (treadDepthResult.max)
                    arrErr = this.addError(arrErr, 'treadDepth', 'The tread maximum is ' + treadDepthResult.max + '"');
            }

            //tread thickness
            if (!this.validateTreadThickness((name === 'treadThicknessIn') ? value : this.state.treadThicknessIn))
                arrErr = this.addError(arrErr, 'treadThickness', 'Tread thickness must be between 0 and 1.5"');

            console.log(arrErr);

            if (arrErr.length > 0)
                this.setState({ errors: arrErr });
            else {

                this.setState({
                    [name]: value,
                    //errors: []
                }, () => {
                    this.updateSteps(this.state);

                    this.setState({
                        stepOptionCount: this.stepOptionCount,
                        stepOpts: this.stepOpts
                    }, () => {
                        //this.output(this.state);
                    });
                });

            }
        }
        //else
            //console.error('input name undefined.');
    };

    validateTotalRiseFt(val) {
        if (((val * 12) + this.state.totalRiseIn) > 144)
            return false;
        else
            return true;
    };

    validateTotalRiseIn(val) {
        if (((this.state.totalRiseFt * 12) + val) > 144)
            return false;
        else
            return true;
    };

    validateTreadDepth(val) {
        console.log('this.state.tray', this.state.tray);

        if (this.state.tray === 0) { //no tray
            if (val < 10)
                return { result: false, min: '10' };
            //this.trayDepthErr.push({ 'key': 'treadDepth', 'msg': 'The tread minimum is 10"' });
        } else if (this.state.tray === 1) { //fortress tray
            if (val < 11)
                return { result: false, min: '11' };
                //this.trayDepthErr.push({ 'key': 'treadDepth', 'msg': 'The tread minimum is 11"' });
            else if (val > 12.25)
                return { result: false, max: '12.25' };
                //this.trayDepthErr.push({ 'key': 'treadDepth', 'msg': 'The tread minimum is 12.25"' });
        }

        return { result: true }; 
    };

    validateTreadThickness(val) {
        console.log('validateTreadThickness', (val < 0 || val > 1.5));
        if (val < 0 || val > 1.5)
            return false;
        else
            return true;
    };

    hasKey(arr, key) {
        return (arr.findIndex(v => v.key === key) > -1);
    };

    addError(arr, key, msg) {
        if(!this.hasKey(arr, key))
            arr.push({ 'key': key, 'msg': msg });

        return arr;
    };

    onStepOptionSelect(event) {
        const target = event.target;

        //reset step select and hide results
        this.setState({
            selectedStepOptionId: 0
        }, () => { });

        if (target.value) {
            
            const value = JSON.parse(target.value);
            //const name = target.name;

            //console.log('selectedStepOptionId', value.id);
            
            this.setState({
                stepRise: value.stepRise,
                bottomStepRise: value.bottomStepRise,
                angle: value.angle,
                bracketSpacing: this.getFraction(value.val),
                stringerLength: value.stringerLength,
                stringerLengthDec: value.stringerLengthDec,
                selectedStepOptionId: value.id,
                stepText: value.steps + ' Steps - ' + value.totalRun + '" Total Run',
                thetaData: value.thetaData,
                pinhole1: value.pinhole1,
                pinhole2: value.pinhole2
            }, () => {
                this.ouput(this.state);
            });

        } else {

            this.setState({
                stepRise: 0,
                bottomStepRise: 0,
                angle: 0,
                bracketSpacing: 0,
                stringerLength: 0,
                stringerLengthDec: 0
            }, () => {
                this.reset();
            });
        }
    };

    //handlePdfClick(e) {
    //    console.log('PDF clicked!');
    //    //ReactPDF.render(<OutputPdf />, '${__dirname}/example.pdf');
    //};

    handleShareClick(e) {
        //console.log('Share clicked!');
    };

    reset() {
        this.arrSteps = [];
        //this.arrTotalRunOpts = [];
        this.arrStringerLengths = [];

        //this.stepOptionCount = 0;
        //this.stepOpts = [];
    };

    //updateSteps!
    updateSteps = (state) => {
        //console.log('Updated State:', state);

        //Num Steps Option 1
        let totalRise = ((state.totalRiseFt * 12) + state.totalRiseIn);
        let stepOpt1Calc = (totalRise / 7.75);
        let stepOpt1 = 0;

        //f2l -> =IF((($C$2*12+$E$2)/7.75)-TRUNC(($C$2*12+$E$2)/7.75)=0,($C$2*12+$E$2)/7.75,TRUNC(($C$2*12+$E$2)/7.75)+1)
        //f2g -> =IF((($C$2*12+$E$2)/7.75)-TRUNC(($C$2*12+$E$2)/7.75)=0,($C$2*12+$E$2)/7.75,TRUNC(($C$2*12+$E$2)/7.75)+1)
        //d2l -> =IF((($C$2*12+$E$2)/7.75)-TRUNC(($C$2*12+$E$2)/7.75)=0,(($C$2*12+$E$2)/7.75)-1,TRUNC(($C$2*12+$E$2)/7.75))
        //d2g -> =IF((($C$2*12+$E$2)/7.75)-TRUNC(($C$2*12+$E$2)/7.75)=0,(($C$2*12+$E$2)/7.75)-1,TRUNC(($C$2*12+$E$2)/7.75))

        if (this.state.configuration <= 1) { //f2g, f2l
            stepOpt1 = (stepOpt1Calc - Math.trunc(stepOpt1Calc) === 0) ? stepOpt1Calc : Math.trunc(stepOpt1Calc + 1);

            this.arrSteps.push({
                'steps': stepOpt1,
                'rise': this.roundToTwo(totalRise / stepOpt1),
                'options': this.getStepOptions((totalRise / stepOpt1).toFixed(5)),
            });
            //Num Steps Option 2
            this.arrSteps.push({
                'steps': stepOpt1 + 1,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 1)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 1)).toFixed(5)),
            });
            //Num Steps Option 3
            this.arrSteps.push({
                'steps': stepOpt1 + 2,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 2)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 2)).toFixed(5)),
            });
            //Num Steps Option 4
            this.arrSteps.push({
                'steps': stepOpt1 + 3,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 3)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 3)).toFixed(5)),
            });

        } else if (this.state.configuration >= 2) { //d2g, d2l
            console.log('drop calc');
            stepOpt1 = (stepOpt1Calc - Math.trunc(stepOpt1Calc) === 0) ? (stepOpt1Calc - 1) : Math.trunc(stepOpt1Calc);

            this.arrSteps.push({
                'steps': stepOpt1,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 1)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 1)).toFixed(5)),
            });
            //Num Steps Option 2
            this.arrSteps.push({
                'steps': stepOpt1 + 1,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 2)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 2)).toFixed(5)),
            });
            //Num Steps Option 3
            this.arrSteps.push({
                'steps': stepOpt1 + 2,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 3)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 3)).toFixed(5)),
            });
            //Num Steps Option 4
            this.arrSteps.push({
                'steps': stepOpt1 + 3,
                'rise': this.roundToTwo(totalRise / (stepOpt1 + 4)),
                'options': this.getStepOptions((totalRise / (stepOpt1 + 4)).toFixed(5)),
            });
        }

        this.populateStepOptions();
    };

    //Populates the select options for the user to choose from.
    populateStepOptions = () => {
        let steps = this.arrSteps.slice(0);

        let itemCount = 1;
        let self = this;
        self.stepOpts = [];

        //loop through angles to add step options to the array.
        steps.forEach(function (o, i) {
            steps[i].options.forEach(function (p, j) {
                steps[i].options[j].forEach(function (q, k) {
                    if (q === 0) return;

                    self.stepOpts.push({
                        'id': itemCount,
                        'row': j,
                        'col': k,
                        'val': q,
                        'steps': o.steps,
                        'totalRun': self.getFraction(self.getTotalRun(o.rise, self.arrAngles[j][k])),
                        'maxRun': self.arrMaxRuns[j][k],
                        'stepRise': o.rise,
                        'bottomStepRise': (o.rise - self.state.treadThicknessIn).toFixed(2),
                        'angle': (self.arrAngles[j][k]).toFixed(2) + '°',
                        'stringerLength': self.getFraction(self.getStringerLength(q, o.rise, self.arrAngles[j][k], self.arrMaxRuns[j][k])),
                        'stringerLengthDec': self.getStringerLength(q, o.rise, self.arrAngles[j][k], self.arrMaxRuns[j][k]),
                        'thetaData': self.getThetaData(self.arrAngles[j][k]),
                        'pinhole1': (j + 1) + 'L',
                        'pinhole2': (k + 1) + 'R'
                    });

                    itemCount++;
                });
            });
        });

        this.stepOptionCount = self.stepOpts.length;

        console.log('this.props.stepOpts -> ', this.stepOpts);
    };

    ouput = (state) => {
        console.log('OUTPUTE state:', this.state);
    };

    render() {
        return (
            <div>
                <p>The Fortress Stair Calculator is a tool required when using Fortress’ Adjustable Stair Bracket.  After entering a few parameters, the calculator will walk you thru all the steps required to assemble a stair stringer using Fortress’ Evolution Steel Stair System.</p>
                <p>If you’re using Fortress’ 7”/11” or 7.75”/11” Fixed Rise/Run Brackets, <FixedRiseRunPDF />.  This calculator is not required for that installation.</p>
                <p>&nbsp;</p>

                <HelpModal className="modal-lg modal-dialog-scrollable" title="Stair Calculator Help" />
                <h5><span className="step ">1</span> Choose a Configuration</h5>

                <ListGroup className="list-group list-group-horizontal-sm" id="stairConfiguration" role="tablist">
                    <StairConfigurationItem
                        value="0"
                        description="flushToGrade"
                        text="Flush to Grade"
                        className={this.state.configuration === 0 ? 'active' : ''}
                        isActive={this.state.configuration === 0 ? true : false }
                        onClick={() => this.setConfiguration(0)}
                    />
                    <StairConfigurationItem
                        value="1"
                        description="flushToLanding"
                        text="Flush to Landing"
                        className={this.state.configuration === 1 ? 'active' : ''}
                        isActive={this.state.configuration === 1 ? true : false}
                        onClick={() => this.setConfiguration(1)}
                    />
                    <StairConfigurationItem
                        value="2"
                        description="dropToGrade"
                        text="Drop to Grade"
                        className={this.state.configuration === 2 ? 'active' : ''}
                        isActive={this.state.configuration === 2 ? true : false}
                        onClick={() => this.setConfiguration(2)}
                    />
                    <StairConfigurationItem
                        value="3"
                        description="dropToLanding"
                        text="Drop to Landing"
                        className={this.state.configuration === 3 ? 'active' : ''}
                        isActive={this.state.configuration === 3 ? true : false}
                        onClick={() => this.setConfiguration(3)}
                    />
                </ListGroup>
                <ConfigurationModal title={'Stair Calculator Help'} className="modal-lg modal-dialog-scrollable" />

                <p>&nbsp;</p>

                {this.state.errors.length > 0 ?
                    <Errors errors={this.state.errors} />
                : ''}
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <RiseAndTread
                            totalRiseFt={this.state.totalRiseFt.toString()}
                            totalRiseIn={this.state.totalRiseIn.toString()}
                            treadDepthIn={this.state.treadDepthIn.toString()}
                            treadThicknessIn={this.state.treadThicknessIn.toString()}
                            hideThickness={(this.state.configuration === 1 || this.state.configuration === 3) ? true : false} //hides thickness input
                            handleInputChange={(e) => this.onInputChange(e)}
                        />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <TrayAndStringer
                            handleInputChange={(e) => this.onInputChange(e)}
                        />
                        <br />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <StepSelect
                            handleStepOptionSelect={(e) => this.onStepOptionSelect(e)}
                            stepCount={this.state.stepOptionCount}
                            stepOptions={this.state.stepOpts}
                            hasErrors={this.state.errors.length > 0}
                        />
                    </div>
                </div>

                <p>&nbsp;</p>
                
                <StairCalcResults
                    stepRise={this.getFraction(this.state.stepRise)}
                    bottomStepRise={this.getFraction(this.state.bottomStepRise)}
                    angle={this.state.angle}
                    bracketSpacing={this.state.bracketSpacing}
                    stringerLength={this.state.stringerLength}
                    stringerLengthDec={this.state.stringerLengthDec}
                    pinholeLocations={this.state.pinhole1 + ', ' + this.state.pinhole2}
                    theta1={(this.state.thetaData && this.state.thetaData.theta1) ? this.state.thetaData.theta1.toFixed(2) : ''}
                    theta2={(this.state.thetaData && this.state.thetaData.theta2) ? this.state.thetaData.theta2.toFixed(2) : ''}
                    x1={(this.state.thetaData && this.state.thetaData.x1) ? this.getFraction(this.state.thetaData.x1) : ''}
                    x2={(this.state.thetaData && this.state.thetaData.x2) ? this.getFraction(this.state.thetaData.x2) : ''}
                    x3={(this.state.thetaData && this.state.thetaData.x3) ? this.getFraction(this.state.thetaData.x3) : ''}
                    handlePdfClick={(e) => this.handlePdfClick(e)}
                    handleFixedRiseRunPdfClick={(e) => this.handleFixedRiseRunPdfClick(e)}
                    handleShareClick={(e) => this.handleShareClick(e)}
                    visible={this.state.selectedStepOptionId > 0}
                    pdfData={this.state}
                    configuration={this.state.configuration}
                />
            </div>
        );
    }
}


//key = { this.stepSelectKey }