import React, { Component } from 'react';

export class Errors extends Component {
    render() {
        return (
            <div className="alert alert-danger" role="alert">
                <ul className="error-list">
                    {this.props.errors.map(err => (
                        <li>{err.msg}</li>
                    ))}
                </ul>
            </div>
        )
    }
}