import React, { Component } from 'react';

export class TrayAndStringer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
        };
    };

    handleInputChange(e) {
        //console.log(e.target.name, e.target.value);
        this.props.handleInputChange(e);
    }    

    focus(e) {
        e.target.select();
    };

    render() {
        return (
            <div>
                <h5><span className="step">3</span> Tray and Stringer</h5>
                Tray:<br />
                <div className="custom-control form-check form-check-inline">
                    <input type="radio" id="trayFortress" name="tray" className="form-check-input" value="1" onChange={(e) => this.handleInputChange(e)} />
                    <label className="form-check-label size-sm" htmlFor="trayFortress">Fortress Evolution Tray</label>
                </div>
                <div className="custom-control form-check form-check-inline mb-3">
                    <input type="radio" id="trayNone" name="tray" className="form-check-input" value="0" defaultChecked onChange={(e) => this.handleInputChange(e)} />
                    <label className="form-check-label size-sm" htmlFor="trayNone">No Tray</label>
                </div>
                <br />
                
                Stringer:<br />
                <div className="custom-control form-check form-check-inline">
                    <input type="radio" id="stringerJoist" name="stringer" className="form-check-input" value="0" defaultChecked onChange={(e) => this.handleInputChange(e)} />
                    <label className="form-check-label size-sm" htmlFor="stringerJoist">Evolution 2" x 6" Joist as Stringer</label>
                </div>
                <div className="custom-control form-check form-check-inline">
                    <input type="radio" id="stringerBeam" name="stringer" className="form-check-input" value="1" onChange={(e) => this.handleInputChange(e)} />
                    <label className="form-check-label size-sm" htmlFor="stringerBeam">Evolution 2" x 11" Beam as Stringer</label>
                </div>
            </div>
        );
    };
}