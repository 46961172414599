import './App.css';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { StairCalc } from './components/StairCalc';

function App() {
  return (
    <Layout>
      <StairCalc />
      {/* <Routes>
        <Route exact path='/' component={StairCalc} />
      </Routes> */}
    </Layout>
  );
}

export default App;
